import {Link, Outlet} from "react-router-dom";

import React from 'react';
import {Breadcrumb, Divider, Layout, Menu, theme} from 'antd';

const { Header, Content, Footer } = Layout;

const AuthLayout : React.FC = () => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();


    return (
        <Layout className="min-h-screen">

            <Content className="p-6 flex justify-center items-center">

                <div className="min-h-[300px] min-w-[250px] w-fit h-fit rounded-xl p-4" style={{ background: colorBgContainer }}>
                    <h1 className="text-2xl font-black">
                        Administración <br/>
                        Municipal
                    </h1>
                    <Divider />
                    <p className="text-xs text-gray-400 mb-2">
                        Bienvenido al sistema de administración <br/> municipal
                    </p>
                    <Outlet/>

                    <p className="text-xs text-gray-400 mt-9 mb-1">
                        ¿Olvidaste tu contraseña o <br/>
                        tienes algun problema para acceder? <br/>
                        Contacta a nuestro equipo de <br/>
                        soporte técnico. <br/>
                    </p>

                    <a href="mailto:dante.clobato@hopsersmerk.com" className="underline" target="_blank">Email</a>
                    <Divider type="vertical" />
                    <a href="#" className="underline" target="_blank">FAQS</a>
                    <Divider type="vertical" />
                    <a href="#" className="underline" target="_blank">WhatsApp</a>
                </div>
            </Content>
        </Layout>
    )
}
export default AuthLayout