import {useSupabase} from "../../_supabase/SupabaseProvider";
import {Descriptions, message} from "antd";
import {useCallback, useEffect, useState} from "react";
import {Database} from "../../_supabase/supabase_types";
import 'leaflet/dist/leaflet.css';
import {MapContainer, Marker, TileLayer, useMapEvents} from 'react-leaflet'
import {LatLngExpression} from "leaflet";
import {useTheme} from "../../context/ThemeContext";

interface AgreementLocationProps {
    agreementId: string
}

const AgreementLocation = ({agreementId}: AgreementLocationProps) => {

    const {supabase} = useSupabase();
    const [messageApi, contextHolder] = message.useMessage();
    const {isDarkMode} = useTheme();

    const [agreementLocation, setAgreementLocation] = useState<Database["public"]["Tables"]["Agreement_Location"]["Row"] | null>(null)
    const [suburb, setSuburb] = useState<Database["public"]["Tables"]["_suburbs"]["Row"] | null>(null)
    const [suburbClassification, setSuburbClassification] = useState<Database["public"]["Tables"]["_suburb_classification"]["Row"] | null>(null)
    const [town, setTown] = useState<Database["public"]["Tables"]["_town"]["Row"] | null>(null)
    const [coordinates, setCoordinates] = useState<LatLngExpression | null>(null)

    const MapEventsComponent = () => {
        const map = useMapEvents({
            click: () => {
                if (coordinates) {
                    map.flyTo(coordinates, 18);
                }
            }
        })

        useEffect(() => {
            if (coordinates) {
                map.flyTo(coordinates, 18)
            }
        }, [coordinates])

        return null // return null because we don't want to render anything
    }

    const fetchData = useCallback(async () => {
        try {
            if (!agreementId) throw new Error('No se ha especificado un contrato');

            if (agreementId !== agreementLocation?._agreement_id) {
                await setAgreementLocation(null);
                await setSuburb(null);
            }

            if (!agreementLocation) {
                const {data, error} = await supabase
                    .from('Agreement_Location')
                    .select('*')
                    .eq('_agreement_id', agreementId)
                    .single()
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado la ubicacion del contrato');
                await setAgreementLocation(data)
                await setCoordinates({
                    lat: parseFloat(data?.coordinates?.split(",")[0] ?? '0'),
                    lng: parseFloat(data?.coordinates?.split(",")[1] ?? "0")
                })
            }

            if (!agreementLocation) {
                return;
            }

            if (!suburb) {
                const {data, error} = await supabase
                    .from('_suburbs')
                    .select('*')
                    .eq('id', agreementLocation._suburb_id)
                    .single()
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el barrio');
                await setSuburb(data)
            }

            if (!suburbClassification && suburb?._suburb_classification_id) {
                const {data, error} = await supabase
                    .from('_suburb_classification')
                    .select('*')
                    .eq('id', suburb?._suburb_classification_id)
                    .single()
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado la clasificacion del barrio');
                await setSuburbClassification(data)
            }

            if (!town && suburb?._town_id) {
                const {data, error} = await supabase
                    .from('_town')
                    .select('*')
                    .eq('id', suburb?._town_id)
                    .single()
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el municipio');
                await setTown(data)
            }
        } catch (error: any) {
            messageApi.error(error.message)
        }
    }, [agreementId, agreementLocation, messageApi, suburb, suburbClassification, supabase, town])


    useEffect(() => {
        fetchData();

    }, [fetchData])

    return (
        <div>
            {contextHolder}
            <h2>Ubicacion Del Contrato</h2>

            <Descriptions
                column={{
                    lg: 6,
                    md: 3,
                    sm: 2,
                    xs: 1,
                }}
            >
                <Descriptions.Item
                    span={6}
                    label="Direccion">
                    {agreementLocation?.address}
                    {agreementLocation?.settlement?.length ?? 0 > 0 ? `, ${agreementLocation?.settlement}` : ` `}
                    &nbsp;{suburb?.type_suburb} {suburb?.name}, {suburb?.zip}, {town?.name}
                </Descriptions.Item>
                <Descriptions.Item
                    span={6}
                    label="Clasificacion de la colonia">
                    {suburbClassification?.name}
                </Descriptions.Item>
            </Descriptions>

            <MapContainer center={coordinates ?? {lat: 0, lng: 0}} zoom={6}
                          className={"w-full h-96"}
            >
                {
                    !isDarkMode ?
                        <TileLayer
                            attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'}
                            url={'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'}
                            subdomains={'abcd'}

                        />
                        :
                        <TileLayer
                            attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'}
                            url={'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'}
                            subdomains={'abcd'}

                        />
                }
                {coordinates && <Marker position={coordinates}/>}
                <MapEventsComponent/>
            </MapContainer>
        </div>
    )
}

export default AgreementLocation