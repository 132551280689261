import {
    Button,
    message,
    Modal,
    Space,
    Table,
    Tag,
    Descriptions,
    Divider,
    Popconfirm,
    Input,
    Select,
    Card,
    Collapse, theme, Result
} from "antd";
import type {ColumnsType} from 'antd/es/table';
import {useEffect, useState} from "react";
import {useSupabase} from "../../../_supabase/SupabaseProvider";
import {faPenToSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Database} from "../../../_supabase/supabase_types";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import {CaretRightOutlined} from '@ant-design/icons';

interface RowCitizen {
    curp: string
    gender: string
    id: string
    name: string
    phone_number: string | null
    surnames: string
}

interface Citizen {
    id: string
    created_at: string
    name: string
    surnames: string
    rfc: string
    curp: string
    identification_number: string
    _type_identification_id: number
    email: string
    phone_number: string
    gender: string
    _type_tax_regime_id: number
    deleted: boolean
    type_identification: Type
    type_tax_regime: Type
}

interface Type {
    id: number
    _type_group_id: number
    name: string
}

const PadronPage = () => {

    const {supabase} = useSupabase();
    const [messageApi, contextHolder] = message.useMessage();

    const [rowData, setRowData] = useState<RowCitizen[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string>("")
    const [searchField, setSearchField] = useState<string>("curp")

    const [modalOpen, setModalOpen] = useState(false);
    const [modalReactComponent, setModalReactComponent] = useState<JSX.Element | null>(null);

    const {Search} = Input;
    const {Option} = Select;
    const {Panel} = Collapse;
    const {token} = theme.useToken();

    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };

    const columns: ColumnsType<RowCitizen> = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <p>
                    {record.name} {record.surnames}
                </p>
            ),
        },
        {
            title: 'CURP',
            dataIndex: 'curp',
            key: 'curp',
            render: (text, record) => (
                <Button type="dashed" className={"w-full"} onClick={
                    () => handeSeeCitizenDetail(record.id)
                }>
                    {record.curp}
                </Button>
            ),
        },
        {
            title: 'Teléfono',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Género',
            dataIndex: 'gender',
            render: (text, record) => (
                <Space size="middle">
                    <Tag color={text.toUpperCase() !== 'M' ? "magenta" : "blue"} key={text}>
                        {text.toUpperCase() === 'M' ? 'Masculino' : 'Femenino'}
                    </Tag>
                </Space>
            ),
        },
        {
            title: 'Acciones',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`/Padron/Citizen/${_.id}`}>
                        <Button icon={<FontAwesomeIcon icon={faPenToSquare}/>}/>
                    </Link>
                    <Popconfirm
                        placement="left"
                        title="¿Estás seguro de eliminar este registro?"
                        description="No podrás recuperar este registro una vez eliminado"
                        onConfirm={() => handleConfirmDelete(record.id)}
                        okText="Si"
                        cancelText="Cancelar"
                        okButtonProps={{className: 'bg-blue-600'}}
                    >
                        <Button icon={<FontAwesomeIcon icon={faTrashCan}/>}/>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const handleChangeSearchField = (value: string) => {
        console.log(`selected ${value}`);
        setSearchField(value)
    }
    const selectBefore = (
        <Select defaultValue="curp"
                onChange={handleChangeSearchField}>
            <Option value="curp">CURP</Option>
            <Option value="name">Nombre</Option>
            <Option value="surnames">Apellidos</Option>
            <Option value="phone_number">Teléfono</Option>
        </Select>
    );


    useEffect(() => {
        getData(1)
        setCurrentPage(1)

    }, [messageApi, supabase, searchValue])

    const getData = async (page: number) => {
        try {
            setLoading(true)

            //count how many rows are in the table
            const {count, error: errorCount} = await supabase
                .from('Citizen')
                .select('id', {count: 'exact'})
                .eq('deleted', false)
                .ilike(searchField, `%${searchValue}%`);

            if (errorCount) {
                throw errorCount;
            }

            if (count === 0) {
                await setRowData([])
                await setLoading(false)
                await setTotalPages(0)
                return;
            }

            if (page > Math.ceil((count ?? 0) / 10)) {
                await setCurrentPage(1)
                page = 1
            }

            let {data: Citizen, error} = await supabase
                .from('Citizen')
                .select('id, name, surnames, curp, phone_number, gender')
                .eq('deleted', false)
                .ilike(searchField, `%${searchValue}%`)
                .order('created_at', {ascending: false})
                .range((page - 1) * 10, page * 10 - 1)

            if (error) {
                throw errorCount;
            }

            if (Citizen) {
                setRowData(Citizen)
                setLoading(false)
                setTotalPages(Math.ceil(count ?? 0 / 10))
            }
        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            });
        }
    }

    const handleConfirmDelete = async (citizenId: string) => {
        try {
            const {error: errorCitizenUpdated} = await supabase
                .from('Citizen')
                .update({deleted: true})
                .eq('id', citizenId)
                .single()

            if (errorCitizenUpdated) {
                messageApi.open({
                    type: 'error',
                    content: errorCitizenUpdated.message,
                });

                return;
            }

            const {error: errorAddressUpdated} = await supabase
                .from('Address')
                .update({deleted: true, updated_at: new Date().toISOString()})
                .eq('_citizen_id', citizenId)

            if (errorAddressUpdated) {
                messageApi.open({
                    type: 'error',
                    content: errorAddressUpdated.message,
                });

                return;
            }

            messageApi.open({
                type: 'success',
                content: 'Ciudadano eliminado correctamente',
            });

            getData(currentPage)

        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            });
        }
    }

    const handeSeeCitizenDetail = async (citizenId: string) => {

        if (!citizenId) {
            messageApi.open({
                type: 'error',
                content: 'No se encontró el ciudadano',
            });

            return;
        }

        // setCitizenEditing(citizenId)

        const {data: citizenFound, error: errorCitizenFound} = await supabase
            .from<"Citizen", Database['public']['Tables']['Citizen']>('Citizen')
            .select('*')
            .eq('id', citizenId)
            .eq('deleted', false)
            .single()
        if (errorCitizenFound) {
            messageApi.open({
                type: 'error',
                content: errorCitizenFound.message,
            });

            return;
        }

        const {data: addressFound, error: errorAddressFound} = await supabase
            .from('Address')
            .select('*')
            .eq('_citizen_id', citizenId)
            .eq('deleted', false);

        if (errorAddressFound) {
            messageApi.open({
                type: 'error',
                content: errorAddressFound.message,
            });

            return;
        }

        const typesIds: number[] = [];

        if (citizenFound._type_identification_id) {
            typesIds.push(citizenFound._type_identification_id)
        }
        if (citizenFound._type_tax_regime_id) {
            typesIds.push(citizenFound._type_tax_regime_id)
        }

        addressFound?.forEach(address => {
            if (address._type_address_id) {
                typesIds.push(address._type_address_id)
            }
        });

        const {data: typesFound, error: errorTypesFound} = await supabase
            .from('_types')
            .select('*')
            .in('id', typesIds)

        if (errorTypesFound) {
            messageApi.open({
                type: 'error',
                content: errorTypesFound.message,
            });

            return;
        }

        const content = (
            <>
                <Link to={`/Padron/Citizen/${citizenFound.id}`}>
                    <Button
                        icon={<FontAwesomeIcon icon={faPenToSquare}/>} className="mb-3"/>
                </Link>
                <Descriptions title={citizenFound.name + ' ' + citizenFound.surnames}
                              column={{xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
                >
                    <Descriptions.Item
                        label="Creado en">{new Date(citizenFound.created_at).toLocaleDateString()}</Descriptions.Item>
                    <Descriptions.Item label="RFC">{citizenFound.rfc}</Descriptions.Item>
                    <Descriptions.Item label="CURP">{citizenFound.curp}</Descriptions.Item>
                    <Descriptions.Item label="Correo electrónico" span={2}>
                        {citizenFound.email ?? 'No especificado'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Género">
                        {citizenFound.gender.toUpperCase() === 'M' ? 'Masculino' : 'Femenino'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Teléfono" span={3}>
                        {citizenFound.phone_number ?? 'No especificado'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Régimen fiscal" span={3}>
                        {!citizenFound._type_tax_regime_id ? 'No especificado' : typesFound.find(type => type.id === citizenFound._type_tax_regime_id)?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipo de identificación" span={3}>
                        {citizenFound._type_identification_id ? typesFound.find(type => type.id === citizenFound._type_identification_id)?.name : 'No especificado'}
                    </Descriptions.Item>
                    <Descriptions.Item label="# de identificación" span={3}>
                        <div>{citizenFound.identification_number ?? 'No especificado'}</div>
                    </Descriptions.Item>
                </Descriptions>

                <Collapse
                    bordered={false}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    style={{background: token.colorBgContainer}}
                >
                    <Panel header="Direcciones" key="1" style={panelStyle}>


                        {
                            addressFound?.length > 0 ?
                                addressFound?.map((
                                    address, index) => (
                                    <Descriptions title={"#" + (index + 1)}
                                                  column={{xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
                                                  key={address.id}
                                    >
                                        <Descriptions.Item label="Creado en">
                                            {new Date(address.created_at).toLocaleDateString()}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Actuaizado en" span={2}>
                                            {new Date(address.updated_at).toLocaleDateString()}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Tipo de dirección" span={3}>
                                            {typesFound.find(type => type.id === address._type_address_id)?.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Dirección" span={3}>
                                            {address.address}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Referencias" span={3}>
                                            {address.reference}
                                        </Descriptions.Item>
                                    </Descriptions>
                                ))
                                : <Result
                                    title="Aún no se han registrado direcciones"
                                />
                        }
                    </Panel>
                </Collapse>
            </>
        );

        await setModalReactComponent(content)

        await setModalOpen(true)
    }

    const onSearch = async (value: string) => {
        await setSearchValue(value);
        await getData(1);
    }

    return (
        <Space
            direction="vertical"
            className={"w-full"}
        >
            {contextHolder}
            <Card className="mb-4">
                <Space align="end"
                       size={100}
                       className="w-full justify-between">
                    <div>
                        <h1 className="text-6xl font-bold mb-0">Padron</h1>
                        <p className="text-sm text-gray-400">Lista de ciudadanos registrados en el sistema</p>
                    </div>
                    <Link to={"/Padron/Citizen/New"}>
                        <Button type="primary" icon={<FontAwesomeIcon icon={faPlus}/>} size="large">
                            &nbsp;Nuevo ciudadano
                        </Button>
                    </Link>
                </Space>
            </Card>


            <Search placeholder="Buscar..."
                    allowClear
                    onSearch={onSearch}
                    size="large"
                    enterButton={"Buscar"}
                    addonBefore={selectBefore}
                    loading={loading}
                    className="mb-1"
                    classNames={{
                        input: 'border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-black',
                    }}/>
            <Table
                columns={columns}
                dataSource={rowData}
                rowKey="id"
                size="small"
                loading={loading}
                pagination={{
                    total: totalPages,
                    pageSize: 10,
                }}
                onChange={({current}) => {
                    getData(current ?? 1)
                    setCurrentPage(current ?? 1)
                }}
            />

            <Modal
                centered
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                footer={null}
            >
                {modalReactComponent}
            </Modal>
        </Space>
    )
}

export default PadronPage