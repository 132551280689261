import React from 'react'
import { Routes, Route } from 'react-router-dom';
import AuthLayout from "./Components/Shared/AuthLayout";
import Login from "./Pages/Auth/Login";
import AdminLayout from "./Components/Shared/AdminLayout";
import Index from "./Pages/Admin";
import PadronPage from "./Pages/Admin/Padron";
import NotFound from "./Pages/Error/NotFound";
import NewCitizen from "./Pages/Admin/Padron/Citizen/NewCitizen";
import EditCitizen from "./Pages/Admin/Padron/Citizen/EditCitizen";
import Agreement from "./Pages/Admin/Agreement";

function App() {

    return (
        <Routes>
            <Route element={<AuthLayout/>}>
                <Route path={'/login'} element={<Login/>}/>
            </Route>
            <Route element={<AdminLayout/>}>
                <Route path={'/'} element={<Index/>}/>
                <Route path={'/Agreement'} element={<Agreement/>}/>
                <Route path={'/Padron'} element={<PadronPage/>}/>
                <Route path={'/Padron/Citizen/New'} element={<NewCitizen/>}/>
                <Route path={'/Padron/Citizen/:userId'} element={<EditCitizen/>}/>
            </Route>

            <Route path={'*'} element={<NotFound/>}/>
        </Routes>
    );
}

export default App;
