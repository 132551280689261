import {createContext, useContext, useState, FunctionComponent, useEffect} from "react";
import Cookies from "universal-cookie";

interface ThemeContextType {
    isDarkMode: boolean;
    toggleDarkMode: () => void;
}

const defaultThemeContextValue: ThemeContextType = {
    isDarkMode: true,
    toggleDarkMode: () => {},
}

export const ThemeContext = createContext<ThemeContextType>(defaultThemeContextValue);

export const useTheme = (): ThemeContextType => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme debe estar dentro del proveedor ThemeContext');
    }

    return context;
}

interface ThemeProviderProps {
    children: React.ReactNode;  // TypeScript necesita que especifiques las props
}

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({children}) => {

    const cookies = new Cookies();

    const [isDarkMode, setIsDarkMode] = useState<boolean>(true);

    const toggleDarkMode = () => {
        console.log('toggleDarkMode')
        setIsDarkMode(!isDarkMode)
        cookies.set('darkMode', !isDarkMode, {path: '/'})
    }

    const value = {
        isDarkMode,
        toggleDarkMode,
    };

    useEffect(() => {
        const darkModeCookie = cookies.get('darkMode')
        if (darkModeCookie) {
            setIsDarkMode(darkModeCookie === 'true')
        }
    }, [])

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    )
}