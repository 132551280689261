import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import SupabaseProvider from "./_supabase/SupabaseProvider";
import {ThemeProvider} from "./context/ThemeContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <SupabaseProvider>
                <ThemeProvider>
                    <App/>
                </ThemeProvider>
            </SupabaseProvider>
        </BrowserRouter>
    </React.StrictMode>
);
