import {useSupabase} from "../../_supabase/SupabaseProvider";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Form, Input, message} from 'antd';

interface LoginProps {
    email: string
    password: string
}

const Login = () => {

    const {session, supabase} = useSupabase()
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (session) {
            console.log(session)
            navigate('/')
        }

    }, [navigate, session])

    const handleLogin = async (values: LoginProps) => {
        const {error} = await supabase.auth.signInWithPassword(
            {
                email: values.email,
                password: values.password
            }
        )

        if (error) {
            messageApi.open({
                type: 'error',
                content: error.message,
            });
            return;
        }

        navigate('/')

    }

    return (
        <>
            {contextHolder}
            <Form
                name="basic"
                labelCol={{span: 16}}
                style={{maxWidth: 600}}
                initialValues={{remember: true}}
                onFinish={handleLogin}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[{
                        required: true, message: 'Tu correo electrónico es necesario!'
                    }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[{
                        required: true, message:
                            'La contrseña es necesaria!'
                    }]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" className="bg-blue-600 w-full" htmlType="submit">
                        Entrar
                    </Button>
                </Form.Item>
            </Form>

        </>
    )
}

export default Login