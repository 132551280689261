import {Button, Card, Collapse, Descriptions, Divider, Input, message, Modal, Result, Space, Table, Tabs} from "antd";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faPlus, faTachographDigital, faUsers} from "@fortawesome/free-solid-svg-icons";
import {useSupabase} from "../../../_supabase/SupabaseProvider";
import {ColumnsType} from "antd/es/table";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {Database} from "../../../_supabase/supabase_types";
import {useEffect, useState} from "react";
import {CaretRightOutlined} from "@ant-design/icons";
import AgreementCharacteristics from "../../../Components/Agreement/AgreementCharacteristics";
import AgreementUsers from "../../../Components/Agreement/AgreementUsers";
import AgreementLocation from "../../../Components/Agreement/AgreementLocation";

interface AgreementProps {
    agreement: Database["public"]["Tables"]["Agreement"]["Row"];
    citizen: Database["public"]["Tables"]["Citizen"]["Row"];
    location: Database["public"]["Tables"]["Agreement_Location"]["Row"];
    suburb: Database["public"]["Tables"]["_suburbs"]["Row"];
}

const Agreement = () => {

    const {supabase} = useSupabase();
    const [messageApi, contextHolder] = message.useMessage();

    const [rowData, setRowData] = useState<AgreementProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number>(0);


    const [modalOpen, setModalOpen] = useState(false);
    const [modalReactComponent, setModalReactComponent] = useState<JSX.Element | null>(null);

    const {Search} = Input;

    const columns: ColumnsType<AgreementProps> = [
        {
            title: 'Cuenta',
            dataIndex: 'account',
            render: (text, record) => (
                <Button onClick={
                    () => handleSeeAgreementDetails(record.agreement.id)
                } className={"w-full"} type="dashed">{record.agreement.account}</Button>
            ),
        },
        {
            title: 'Propietario',
            dataIndex: 'propietario',
            render: (text, record) => (
                <p>
                    {record.citizen.name} {record.citizen.surnames} <br/>
                    {record.citizen.curp}
                </p>
            ),
        },
        {
            title: 'Ubicación del contrato',
            dataIndex: 'location',
            render: (text, record) => (
                <p>
                    {record.location.address}, {record.location.settlement},<br/>
                    {record.suburb.zip}, {record.suburb.type_suburb}: {record.suburb.name}
                </p>
            ),
        },
        {
            title: 'Acciones',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`/Agreement/${_.agreement.id}`}>
                        <Button icon={<FontAwesomeIcon icon={faPenToSquare}/>}/>
                    </Link>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        getData(1)
    }, [messageApi, supabase])

    const getData = async (page: number, searchValue: string = "") => {
        try {

            setLoading(true)

            //count how many rows are in the table
            const {count, error: errorCount} = await supabase
                .from('Agreement')
                .select('id', {count: 'exact'})
                .ilike('account', `%${searchValue}%`);

            if (errorCount) {
                throw errorCount;
            }

            if (count === 0) {
                await setRowData([])
                await setLoading(false)
                await setTotalPages(1)
                return;
            }

            if (page > Math.ceil((count ?? 0) / 10)) {
                page = 1
            }

            let {data: Agreements, error: agreementError} = await supabase
                .from('Agreement')
                .select('*')
                .ilike('account', `%${searchValue}%`)
                .order('created_at', {ascending: false})
                .range((page - 1) * 10, page * 10 - 1)

            if (agreementError) {
                throw agreementError;
            }

            if (Agreements) {
                const agreementsIds = Agreements?.map(x => x.id)

                const {data: agreementCitizen, error: errorAgreementCitizen} = await supabase
                    .from('Agreement_Citizen')
                    .select('*')
                    .in('_agreement_id', agreementsIds ?? [])

                if (errorAgreementCitizen) {
                    throw errorAgreementCitizen;
                }

                const citizenPropietariosIds = agreementCitizen?.map(x => x._citizen_id)

                const {data: citizens, error: errorCitizen} = await supabase
                    .from('Citizen')
                    .select('*')
                    .in('id', citizenPropietariosIds ?? [])

                if (errorCitizen) {
                    throw errorCitizen;
                }

                const {data: agreementLocations, error: errorAgreementLocations} = await supabase
                    .from('Agreement_Location')
                    .select('*')
                    .in('_agreement_id', agreementsIds ?? [])

                if (errorAgreementLocations) {
                    throw errorAgreementLocations;
                }

                const suburbIds = agreementLocations?.map(x => x._suburb_id)

                const {data: suburbs, error: errorSuburbs} = await supabase
                    .from('_suburbs')
                    .select('*')
                    .in('id', suburbIds ?? [])

                if (errorSuburbs) {
                    throw errorSuburbs;
                }


                setRowData(Agreements.map(x => {

                    const agreementCitizenFound = agreementCitizen?.find(y => y._agreement_id === x.id)
                    const agreementLocationFound = agreementLocations?.find(y => y._agreement_id === x.id) ?? {} as Database["public"]["Tables"]["Agreement_Location"]["Row"];
                    const agreementProperties: AgreementProps = {
                        agreement: x,
                        citizen: citizens?.find(y => y.id === agreementCitizenFound?._citizen_id) ?? {} as Database["public"]["Tables"]["Citizen"]["Row"],
                        location: agreementLocationFound,
                        suburb: suburbs?.find(y => y.id === agreementLocationFound._suburb_id) ?? {} as Database["public"]["Tables"]["_suburbs"]["Row"],
                    }
                    return agreementProperties
                }))
                await setLoading(false)
                await setTotalPages(Math.ceil(count ?? 0 / 10))
            } else {
                await setRowData([])
                await setLoading(false)
                await setTotalPages(1)
            }
        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            });
        }
    }

    const handleSeeAgreementDetails = async (agreementId: string) => {
        try {
            if (!agreementId) {
                throw new Error("No se ha encontrado el contrato")
            }


            const content = (
                <>

                    <Link to={`/Agreement/${agreementId}`}>
                        <Button
                            icon={<FontAwesomeIcon icon={faPenToSquare}/>} className="mb-3"/>
                    </Link>
                    <h2 className={"text-3xl font-bold mb-5"}>Contrato</h2>
                    <Tabs
                        className={"min-h-[550px]"}
                        tabPosition={"left"}
                        items={[
                            {
                                label: <FontAwesomeIcon icon={faTachographDigital}/>,
                                key: "Características",
                                children: <AgreementCharacteristics agreementId={agreementId}/>,
                            },
                            {
                                label: <FontAwesomeIcon icon={faUsers}/>,
                                key: "Usuarios",
                                children: <AgreementUsers agreementId={agreementId}/>,
                            },
                            {
                                label: <FontAwesomeIcon icon={faLocationDot}/>,
                                key: "Ubicacion",
                                children: <AgreementLocation agreementId={agreementId}/>,
                            }
                        ]}
                    />
                </>
            );

            await setModalReactComponent(content)

            await setModalOpen(true)
        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            });
        }
    }

    const onSearch = async (value: string) => {
        await getData(1, value);
    }

    return (
        <Space
            direction="vertical"
            className={"w-full"}
        >
            {contextHolder}
            <Card className="mb-4">
                <Space align="end"
                       size={100}
                       className="w-full justify-between">
                    <div>
                        <h1 className="text-6xl font-bold mb-0">Contratos</h1>
                        <p className="text-sm text-gray-400">Lista de contratos registrados en el sistema</p>
                    </div>
                    <Link to={"/Agreements/New"}>
                        <Button type="primary" icon={<FontAwesomeIcon icon={faPlus}/>} size="large">
                            &nbsp;Nuevo contrato
                        </Button>
                    </Link>
                </Space>
            </Card>

            <Search placeholder="Buscar..."
                    allowClear
                    onSearch={onSearch}
                    size="large"
                    enterButton={"Buscar"}
                    addonBefore="Cuenta:"
                    loading={loading}
                    className="mb-1"
                    classNames={{
                        input: 'border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-black',
                    }}/>
            <Table
                columns={columns}
                dataSource={rowData}
                rowKey={record => record.agreement.id}
                size="small"
                loading={loading}
                pagination={{
                    total: totalPages,
                    pageSize: 10,
                }}
                onChange={({current}) => {
                    getData(current ?? 1)
                }}
            />

            <Modal
                centered
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                footer={null}
                width={700}
            >
                {modalReactComponent}
            </Modal>
        </Space>
    )
}

export default Agreement