import {createContext, useContext, useEffect, useState} from 'react'
import {createClient, Session, SupabaseClient} from '@supabase/supabase-js'
import {useNavigate} from "react-router-dom";
import {Database} from "./supabase_types";
import {message} from "antd";

type SupabaseContext = {
    supabase: SupabaseClient<Database>,
    session: Session | null,
    handleLogout: () => void
}

const Context = createContext<SupabaseContext | undefined>(undefined)

export default function SupabaseProvider({children,}: { children: React.ReactNode }) {

    const [messageApi, contextHolder] = message.useMessage();

    const [supabase] =
        useState(() => createClient<Database>(process.env.REACT_APP_SUPABASE_URL!, process.env.REACT_APP_SUPABASE_ANON!))
    const navigate = useNavigate()

    const [session, setSession] = useState<Session | null>(null)
    useEffect(() => {
        const {
            data: {subscription},
        } = supabase.auth.onAuthStateChange(async () => {

            const {data: {session: currentSession}} = await supabase.auth.getSession();

            if (currentSession) {
                const {data: publicServant, error: publicServantError} = await supabase
                    .from('Public_Servants')
                    .select('*')
                    .eq('_user_id', currentSession?.user?.id)
                    .eq('active', true)
                    .eq('deleted', false)
                    .single()

                if (publicServantError ||!publicServant) {
                    await supabase.auth.signOut();

                    messageApi.destroy();
                    messageApi.error('No tienes acceso a la plataforma');
                }
            }

            await setSession(currentSession)

            if (!currentSession) {
                navigate('/login')
            }

        })

        return () => {
            subscription.unsubscribe()
        }
    }, [messageApi, navigate, supabase])

    const handleLogout = async () => {
        const {error} = await supabase.auth.signOut()

        if (error) {
            console.error(error)
        }
    }

    return (
        <Context.Provider value={{supabase, session, handleLogout}}>
            {contextHolder}
            <>{children}</>
        </Context.Provider>
    )
}

export const useSupabase = () => {
    const context = useContext(Context)

    if (context === undefined) {
        throw new Error('useSupabase must be used inside SupabaseProvider')
    }

    return context
}