import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {message, Modal, Result, Steps} from "antd";
import CitizenForm from "../../../../Components/Padron/CitizenForm";
import AddressForm from "../../../../Components/Padron/AddressForm";
import {Database} from "../../../../_supabase/supabase_types";
import {useSupabase} from "../../../../_supabase/SupabaseProvider";

const EditCitizen = () => {

    const {userId} = useParams<{ userId: string }>()

    const [messageApi, contextHolder] = message.useMessage();
    const {supabase} = useSupabase();

    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [status, setStatus] = useState<string>('process');
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [citizenToEdit, setCitizenToEdit] = useState<Database["public"]["Tables"]["Citizen"]["Row"] | null>(null);

    useEffect(() => {
        fetchDataToEdit()

    }, [userId])

    const fetchDataToEdit = async () => {

        if (citizenToEdit) return

        const {data: citizenData, error: citizenError} = await supabase
            .from('Citizen')
            .select('*')
            .eq('id', userId)
            .single()

        if (citizenError) {
            await messageApi.destroy();
            await messageApi.error('Ha ocurrido un error al obtener los datos del ciudadano')
            navigate('/Padron')
            return
        }

        await setCitizenToEdit(citizenData)
    }

    const onChangeStep = (value: number) => {
        setCurrentStep(value);
    };

    return (
        <div className={"w-full flex flex-col gap-5"}>

            {contextHolder}
            <Steps
                onChange={citizenToEdit ? onChangeStep : undefined}
                current={currentStep}
                status={status === 'process' ? 'process' : 'finish'}
                items={[
                    {
                        title: 'Datos Personales'
                    },
                    {
                        title: 'Direccion',
                    },
                ]}
                className={"w-2/5 mx-auto"}
            />
            {currentStep === 0 ?
                (
                    <CitizenForm setCitizenCreatedHook={setCitizenToEdit} setOpenModalHook={setOpenModal}
                                 citizenToEdit={citizenToEdit}/>

                )
                : (
                    <AddressForm citizen={citizenToEdit}/>
                )}
        </div>
    )
}

export default EditCitizen