import {useSupabase} from "../../_supabase/SupabaseProvider";
import {Card, Descriptions, message} from "antd";
import {useCallback, useEffect, useState} from "react";
import {Database} from "../../_supabase/supabase_types";

interface AgreementUsersProps {
    agreementId: string
}

const AgreementUsers = ({agreementId}: AgreementUsersProps) => {

    const {supabase} = useSupabase();
    const [messageApi, contextHolder] = message.useMessage();

    const [agreementCitizens, setAgreementCitizens] = useState<Database["public"]["Tables"]["Agreement_Citizen"]["Row"][] | null>(null)
    const [citizens, setCitizens] = useState<Database["public"]["Tables"]["Citizen"]["Row"][] | null>(null)
    const [addresses, setAddresses] = useState<Database["public"]["Tables"]["Address"]["Row"][] | null>(null)
    const [types, setTypes] = useState<Database["public"]["Tables"]["_types"]["Row"][] | null>(null)
    const [states, setStates] = useState<Database["public"]["Tables"]["_states"]["Row"][] | null>(null)

    const fetchData = useCallback(async () => {
        try {
            if (!agreementId) throw new Error('No se ha especificado un contrato');

            if (agreementId !== agreementCitizens?.[0]?._agreement_id) {
                await setAgreementCitizens(null)
                await setCitizens(null)
            }

            if (!agreementCitizens?.length) {
                const {data, error} = await supabase
                    .from('Agreement_Citizen')
                    .select('*')
                    .eq('_agreement_id', agreementId)
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el contrato');
                await setAgreementCitizens(data)
            }

            if (!citizens?.length) {
                const citizenIds: string[] = agreementCitizens?.map(agreementCitizen => agreementCitizen._citizen_id) ?? [];
                const {data, error} = await supabase
                    .from('Citizen')
                    .select('*')
                    .in('id', citizenIds)
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el contrato');
                await setCitizens(data)
            }

            if (!addresses?.length) {
                const citizenIds: string[] = agreementCitizens?.map(agreementCitizen => agreementCitizen._citizen_id) ?? [];

                const {data, error} = await supabase
                    .from('Address')
                    .select('*')
                    .in('_citizen_id', citizenIds)
                    .eq('_type_address_id', 24)
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el contrato');
                await setAddresses(data)
            }

            if (!types?.length) {
                const typesIds: number[] = [];
                typesIds.push(...addresses?.map(address => address._type_address_id) ?? []);
                typesIds.push(...citizens?.map(citizen => citizen._type_identification_id ?? 0) ?? []);
                typesIds.push(...citizens?.map(citizen => citizen._type_tax_regime_id ?? 0) ?? []);
                typesIds.push(...agreementCitizens?.map(agreementCitizen => agreementCitizen._type_property_id ?? 0) ?? []);

                const {data, error} = await supabase
                    .from('_types')
                    .select('*')
                    .in('id', typesIds)
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el contrato');
                await setTypes(data)
            }

        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            });
        }

    }, [addresses, agreementCitizens, agreementId, citizens, messageApi, supabase, types?.length])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const renderCitizens = (agreementCitizens: Database["public"]["Tables"]["Agreement_Citizen"]["Row"][] | null, citizens: Database["public"]["Tables"]["Citizen"]["Row"][] | null, addresses: Database["public"]["Tables"]["Address"]["Row"][] | null, types: Database["public"]["Tables"]["_types"]["Row"][] | null) => {
        if (!agreementCitizens?.length || !citizens || !addresses || !types) return null;

        return agreementCitizens.map(agreementCitizen => {

            const citizenRecord = citizens.find(citizen => citizen.id === agreementCitizen._citizen_id)
            const addressRecord = addresses.find(address => address._citizen_id === agreementCitizen._citizen_id)

            return (
                <Card
                    key={agreementCitizen._agreement_id + agreementCitizen._citizen_id}
                    className={"mb-3"}
                >
                    <Descriptions
                        column={{
                            lg: 6,
                            md: 3,
                            sm: 2,
                            xs: 1,
                        }}
                        title={citizenRecord?.name + ' ' + citizenRecord?.surnames}
                    >
                        <Descriptions.Item
                            span={3}
                            label={"Tipo de usuario"}>
                            {types.find(type => type.id === agreementCitizen._type_property_id)?.name}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={3}
                            label={"CURP"}>
                            {citizenRecord?.curp}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={3}
                            label={"Genero"}>
                            {citizenRecord?.gender === 'M' ? 'Masculino' : 'Femenino'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={3}
                            label={"Dirección"}>
                            {addressRecord?.address} {addressRecord?.zip}
                        </Descriptions.Item>

                    </Descriptions>
                </Card>)
        })
    }

    return (
        <div>
            {contextHolder}
            <h2>Usuarios Del Contrato</h2>

            {renderCitizens(agreementCitizens, citizens, addresses, types)}
        </div>
    )
}

export default AgreementUsers