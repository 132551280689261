import {useCallback, useEffect, useState} from "react";
import {Database} from "../../_supabase/supabase_types";
import {useSupabase} from "../../_supabase/SupabaseProvider";
import {Descriptions, Divider, message} from "antd";

interface AgreementCharacteristicsProps {
    agreementId: string
}

const AgreementCharacteristics = ({agreementId}: AgreementCharacteristicsProps) => {

    const {supabase} = useSupabase();
    const [messageApi, contextHolder] = message.useMessage();

    const [agreement, setAgreement] = useState<Database["public"]["Tables"]["Agreement"]["Row"] | null>(null)
    const [types, setTypes] = useState<Database["public"]["Tables"]["_types"]["Row"][] | null>(null)
    const [states, setStates] = useState<Database["public"]["Tables"]["_states"]["Row"][] | null>(null)
    const [agreementConfiguration, setAgreementConfiguration] = useState<Database["public"]["Tables"]["Agreement_Configuration"]["Row"] | null>(null)
    const [agreementDetail, setAgreementDetail] = useState<Database["public"]["Tables"]["Agreement_Detail"]["Row"] | null>(null)


    const fetchData = useCallback(async () => {
        try {
            if (!agreementId) throw new Error('No se ha especificado un contrato');

            if (agreementId !== agreement?.id) {
                await setAgreement(null)
                await setAgreementConfiguration(null)
                await setTypes(null)
                await setAgreementDetail(null)
            }

            if (!agreement) {
                const {data, error} = await supabase
                    .from('Agreement')
                    .select('*')
                    .eq('id', agreementId)
                    .single()
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el contrato');
                await setAgreement(data)
            }

            if (!agreement) {
                return;
            }

            if (!agreementConfiguration) {
                const {data, error} = await supabase
                    .from('Agreement_Configuration')
                    .select('*')
                    .eq('_agreement_id', agreementId)
                    .single()
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado la configuracion del contrato');
                await setAgreementConfiguration(data)
            }

            if (!agreementDetail) {
                const {data, error} = await supabase
                    .from('Agreement_Detail')
                    .select('*')
                    .eq('_agreement_id', agreementId)
                    .single()
                if (error) throw error;
                if (!data) throw new Error('No se ha encontrado el detalle del contrato');
                await setAgreementDetail(data)
            }

            if (!types?.length && agreementConfiguration && agreement) {
                const typesId: number[] = []
                typesId.push(agreement?._type_agreementId ?? 0)
                typesId.push(agreementConfiguration?._type_intake_id ?? 0)
                typesId.push(agreementConfiguration?._type_service_id ?? 0)
                typesId.push(agreementConfiguration?._type_use_id ?? 0)
                typesId.push(agreementConfiguration?._type_consume_id ?? 0)
                typesId.push(agreementConfiguration?._type_regime_id ?? 0)
                typesId.push(agreementConfiguration?._type_period_id ?? 0)
                typesId.push(agreementConfiguration?._type_business_id ?? 0)
                typesId.push(agreementConfiguration?._type_classification_id ?? 0)
                typesId.push(agreementConfiguration?._type_inlet_diameter_id ?? 0)
                typesId.push(agreementConfiguration?._type_service_id ?? 0)

                const {data, error} = await supabase
                    .from('_types')
                    .select('*')
                    .in('id', typesId)
                if (error) throw error;
                if (!data?.length) throw new Error('No se han encontrado los tipos');
                await setTypes(data)
            }

            if (!states?.length && agreementConfiguration) {
                const statesId: number[] = []
                statesId.push(agreementConfiguration?._state_service_id ?? 0)

                const {data, error} = await supabase
                    .from('_states')
                    .select('*')
                    .in('id', statesId)

                if (error) throw error;
                if (!data?.length) throw new Error('No se han encontrado los estados');
                await setStates(data)
            }

        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            });
        }
    }, [agreementId, agreement, agreementConfiguration, agreementDetail, types?.length, states?.length, supabase, messageApi]);  // Asegúrate de incluir todas las dependencias aquí

    useEffect(() => {
        fetchData();

    }, [fetchData])

    const findTypeById = (id: number | undefined): string => {
        if (id === undefined) {
            return '';
        }

        const type = types?.find((type: Database["public"]["Tables"]["_types"]["Row"]) => type.id === id);
        return type?.name || '';
    };

    const findStateById = (id: number | undefined): string => {
        if (id === undefined) {
            return '';
        }

        const state = states?.find((state: Database["public"]["Tables"]["_states"]["Row"]) => state.id === id);
        return state?.name || '';
    }


    return (
        <div>
            {contextHolder}
            <h2>Caracteristicas y Detalles</h2>

            <Descriptions
                column={{
                    lg: 6,
                    md: 3,
                    sm: 2,
                    xs: 1,
                }}
            >
                <Descriptions.Item span={3} label="Cuenta">{agreement?.account}6182</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Tipo de contrato">{
                    types?.find(type => type.id === agreement?._type_agreementId)?.name
                }</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Inicio del contrato">{new Date(agreement?.start_date ?? new Date().toISOString()).toLocaleDateString()}</Descriptions.Item>

                <Descriptions.Item span={3} label="Clave Catastral">
                    {agreementDetail?.catastral_key}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Taxable Base">{agreementDetail?.taxable_base}</Descriptions.Item>
                <Descriptions.Item span={2} label="Terreno">{agreementDetail?.ground}</Descriptions.Item>
                <Descriptions.Item span={2} label="Contruccion">
                    {agreementDetail?.build}
                </Descriptions.Item>

                <Descriptions.Item span={3}
                                   label="Valor del terreno">{agreementDetail?.value_ground}</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Valor de la construccion">{agreementDetail?.value_build}</Descriptions.Item>
                <Descriptions.Item span={6} label="Ultima Actualizacion">
                    {new Date(agreementDetail?.last_update ?? new Date().toISOString()).toLocaleDateString()}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Año">
                    {agreementDetail?.year}
                </Descriptions.Item>
                <Descriptions.Item span={4} label="Tiene Manifiesto">
                    {agreementDetail?.manifest ? 'Si' : 'No'}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions
                column={{
                    lg: 6,
                    md: 3,
                    sm: 2,
                    xs: 1,
                }}
                title="Configuracion"
                className={"mt-5"}
            >

                <Descriptions.Item span={2} label="Toma">
                    {findTypeById(agreementConfiguration?._type_intake_id)}
                </Descriptions.Item>
                <Descriptions.Item span={4} label="Tipo de servicio">
                    {findTypeById(agreementConfiguration?._type_service_id)}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Uso">
                    {findTypeById(agreementConfiguration?._type_use_id)}
                </Descriptions.Item>

                <Descriptions.Item span={2} label="Consumo">
                    {findTypeById(agreementConfiguration?._type_consume_id)}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Regimen">
                    {findTypeById(agreementConfiguration?._type_regime_id)}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Negocio">
                    {findTypeById(agreementConfiguration?._type_business_id)}
                </Descriptions.Item>

                <Descriptions.Item span={2} label="Clasificacion">
                    {findTypeById(agreementConfiguration?._type_classification_id)}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Diametro de entrada">
                    {findTypeById(agreementConfiguration?._type_inlet_diameter_id)}
                </Descriptions.Item>
                <Descriptions.Item span={6} label="Estado del servicio">
                    {findStateById(agreementConfiguration?._state_service_id)}
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default AgreementCharacteristics