import {Button, Card, Form, Input, message, Select} from "antd";
import {useEffect, useState} from "react";
import {Database} from "../../_supabase/supabase_types";
import {useSupabase} from "../../_supabase/SupabaseProvider";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

interface CitizenFormProps {
    setCitizenCreatedHook: (value: Database["public"]["Tables"]["Citizen"]["Row"] | null) => void;
    setOpenModalHook?: (value: boolean) => void;
    citizenToEdit?: Database["public"]["Tables"]["Citizen"]["Row"] | null;
}

const CitizenForm = ({setCitizenCreatedHook, setOpenModalHook, citizenToEdit}: CitizenFormProps) => {

    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const {supabase} = useSupabase();

    const [identificationTypes, setIdentificationTypes] = useState<Database["public"]["Tables"]["_types"]["Row"][] | null>(null);
    const [regimenFiscales, setRegimenFiscales] = useState<Database["public"]["Tables"]["_types"]["Row"][] | null>(null);

    useEffect(() => {
        fetchSelectsData();
        form.setFieldsValue(citizenToEdit ?? undefined);
    }, [messageApi, citizenToEdit]);


    const fetchSelectsData = async () => {

        const {
            data: identificationTypesData,
            error: identificationTypesError
        } = await supabase.from("_types").select("*")
            .eq("_type_group_id", 4);

        if (identificationTypesError) {
            messageApi.error("Error al obtener los tipos de identificacion");
        }
        setIdentificationTypes(identificationTypesData);

        const {
            data: regimenFiscalesData,
            error: regimenFiscalesError
        } = await supabase.from("_types").select("*")
            .eq("_type_group_id", 5);

        if (regimenFiscalesError) {
            messageApi.error("Error al obtener los regimenes fiscales");
        }

        setRegimenFiscales(regimenFiscalesData);
    }

    const handleAddCitizen = async (values: any) => {
        try {
            const {data, error} = await supabase.from("Citizen").insert([
                values
            ]).select("*");

            if (error) {
                messageApi.error("Error al agregar el ciudadano");
            }

            if (!data?.[0]) {
                messageApi.error("Error al agregar el ciudadano");
                return;
            }

            await setCitizenCreatedHook(data[0]);

            messageApi.success("Ciudadano agregado correctamente");

            if (setOpenModalHook)
                setOpenModalHook(true);
        } catch (e) {
            messageApi.error("Error al agregar el ciudadano");
        }
    };

    const handleEditCitizen = async (values: any) => {
        try {
            const {data, error} = await supabase.from("Citizen").update(
                values
            ).eq("id", citizenToEdit?.id!).select("*");

            if (error) {
                messageApi.error("Error al editar el ciudadano");
            }

            if (!data?.[0]) {
                messageApi.error("Error al editar el ciudadano");
                return;
            }

            await setCitizenCreatedHook(data[0]);

            messageApi.success("Ciudadano editado correctamente");

            if (setOpenModalHook)
                setOpenModalHook(true);
        }
        catch (e) {
            messageApi.error("Error al editar el ciudadano");
        }
    }

    return (
        <Card title="Datos del ciudadano"
              bordered={false}
              className={"min-w-[40rem] w-3/6 mx-auto"}
        >
            {contextHolder}
            <Form
                {...layout}
                form={form}
                name="add-citizen"
                onFinish={!citizenToEdit ? handleAddCitizen : handleEditCitizen}
            >

                <Form.Item name="curp" label="CURP" rules={[{
                    required: true,
                    message: 'Ingrese un CURP valido',
                    pattern: new RegExp(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)
                }]}>
                    <Input
                        onInput={(e) => {
                            e.currentTarget.value = e.currentTarget.value.toUpperCase();
                        }}/>
                </Form.Item>
                <Form.Item name="name" label="Nombre" rules={[{
                    required: true,
                    message: 'Informacion requerida'
                }]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="surnames" label="Apellidos" rules={[{
                    required: true,
                    message: 'Informacion requerida'
                }]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="gender" label="Genero"
                           rules={[{
                               required: true,
                               message: 'Por favor seleccione un genero'
                           }]}>
                    <Select
                        placeholder="Genero del ciudadano"
                        allowClear
                        showSearch
                    >
                        <Option key={1} value={"M"}>Masculino</Option>
                        <Option key={2} value={"F"}>Femenino</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="phone_number" label="Numero de telefono"
                           rules={[{
                               required: true,
                               pattern: new RegExp(/^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/),
                               message: 'Ingrese un numero valido'
                           }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item name="email" label="Correo electronico"
                           rules={[{
                               type: 'email',
                               message: 'Ingrese un correo valido'
                           }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item name="rfc" label="RFC"
                           rules={[{
                               message: 'Ingrese un RFC valido',
                               pattern: new RegExp(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/)
                           }]}>
                    <Input
                        onInput={(e) => {
                            e.currentTarget.value = e.currentTarget.value.toUpperCase();
                        }}
                    />
                </Form.Item>
                <Form.Item name="_type_tax_regime_id" label="Tipo de regimen fiscal">
                    <Select
                        placeholder="Seleccione un tipo de regimen fiscal"
                        allowClear
                        showSearch
                    >
                        {regimenFiscales?.map((identificationType) => (
                            <Option key={identificationType.id}
                                    value={identificationType.value}>{identificationType.value} - {identificationType.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="_type_identification_id" label="Tipo de identificacion" rules={[{
                    required: true,

                    message: 'Tipo de identificacion requerida'
                }]}>
                    <Select
                        placeholder="Seleccione un tipo de identificacion"
                        allowClear
                        showSearch
                    >
                        {identificationTypes?.map((identificationType) => (
                            <Option key={identificationType.id}
                                    value={identificationType.id}>{identificationType.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="identification_number" label="Numero de identificacion">
                    <Input/>
                </Form.Item>
                <Form.Item {...tailLayout}>


                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                </Form.Item>
            </Form>

        </Card>
    )
}

export default CitizenForm