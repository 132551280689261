import {Modal, Result, Space, Steps} from "antd";
import {useEffect, useState} from "react";
import {Database} from "../../../../_supabase/supabase_types";
import {useNavigate} from "react-router-dom";
import CitizenForm from "../../../../Components/Padron/CitizenForm";
import AddressForm from "../../../../Components/Padron/AddressForm";


const NewCitizen = () => {

    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [status, setStatus] = useState<string>('process');
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [citizenCreated, setCitizenCreated] = useState<Database["public"]["Tables"]["Citizen"]["Row"] | null>(null);

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <Steps
                current={currentStep}
                status={status === 'process' ? 'process' : 'finish'}
                items={[
                    {
                        title: 'Datos Personales'
                    },
                    {
                        title: 'Direccion',
                    },
                ]}
                className={"w-2/5 mx-auto"}
            />
            {currentStep === 0 ?
                (
                    <CitizenForm setCitizenCreatedHook={setCitizenCreated} setOpenModalHook={setOpenModal}/>
                )
                : (
                    <AddressForm citizen={citizenCreated}/>
                )}
            <Modal
                open={openModal}
                onOk={() => {
                    setOpenModal(false)
                    setCurrentStep(1)
                }}
                onCancel={() => navigate('/Padron')}
                okText='Agregar direccion'
                cancelText='Regresar al padron'
            >
                <Result
                    status="success"
                    title='El ciudadano se ha agregado correctamente'
                    subTitle='Puede continuar agregando la direccion del ciudadano o puede regresar al padron'
                />
            </Modal>
        </div>
    )
}

export default NewCitizen