import {Alert, Button, Card, Form, Input, message, Select, Space, Table, Tag, Tooltip} from "antd";
import {useSupabase} from "../../_supabase/SupabaseProvider";
import {Database} from "../../_supabase/supabase_types";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Marquee from 'react-fast-marquee';
import type {ColumnsType} from 'antd/es/table';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";

const {Option} = Select;
const {TextArea} = Input;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

interface AddressFormProps {
    citizen: Database["public"]["Tables"]["Citizen"]["Row"] | null;
    setOpenModalHook?: (value: boolean) => void;
}


const AddressForm = ({citizen, setOpenModalHook}: AddressFormProps) => {

    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const {supabase} = useSupabase();
    const navigate = useNavigate();

    const [addressTypes, setAddressTypes] = useState<Database["public"]["Tables"]["_types"]["Row"][] | null>(null);
    const [address, setAddress] = useState<Database["public"]["Tables"]["Address"]["Row"][]>([]);

    const columns: ColumnsType<Database["public"]["Tables"]["Address"]["Row"]> = [
        {
            title: 'Direccion',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Codigo Postal',
            dataIndex: 'zip',
            key: 'zip',
        },
        {
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Tipo de Direccion',
            key: '_type_address_id',
            dataIndex: 'type_address_id',
            render: (_, {_type_address_id}) => (
                <>
                    {(() => {
                        const typeAddress = addressTypes?.find(({id}) => id === _type_address_id),
                            color = typeAddress?.value ?? '#722ed1';
                        return (
                            <Tag color={color}>
                                {typeAddress?.name.toUpperCase()}
                            </Tag>
                        );
                    })()}
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_) => (
                <Space size="middle">
                    <Button icon={<FontAwesomeIcon icon={faTrashCan}/>} onClick={() => handleDeleteAddress(_.id)}/>
                </Space>
            ),
        },
    ];


    useEffect(() => {

        if (!citizen) {
            messageApi.destroy();
            messageApi.error("No se pudo recuperar los datos necesarios del ciudadano para crear la direccion.").then(() => {
                navigate("/Padron");
            });
        } else {
            fetchSelectsData();
            fetchAddress();
        }
    }, [messageApi, citizen]);

    const fetchSelectsData = async () => {
        const {
            data: addressTypesData,
            error: addressTypesError
        } = await supabase.from("_types").select("*")
            .eq("_type_group_id", 7);

        if (addressTypesError) {
            messageApi.error("Error al obtener los tipos de direccion");
        }
        setAddressTypes(addressTypesData);
    }

    const fetchAddress = async () => {
        const {data: addressData, error: addressError} = await supabase.from("Address").select("*")
            .eq("_citizen_id", citizen?.id)
            .eq("deleted", false);

        if (addressError) {
            messageApi.error("Error al obtener la direccion del ciudadano");
        }

        //add all addresses to the state
        setAddress(addressData ?? []);
    }

    const handleAddAddress = async (values: any) => {
        const { error: addressError} = await supabase.from("Address").insert([
            {
                address: values.address,
                zip: values.zip,
                reference: values.reference,
                _type_address_id: address.length === 0 ? 24 :  values._type_address_id,
                _citizen_id: citizen?.id ?? "",
            }
        ]);

        if (addressError) {
            messageApi.error("Error al agregar la direccion");
            return;
        }

        messageApi.success("Direccion agregada correctamente");

        //add the new address to the state
        fetchAddress();
    }

    const handleDeleteAddress = async (addressId: Database["public"]["Tables"]["Address"]["Row"]["id"]) => {
        //update the address
        const {error: addressError} = await supabase.from("Address").update({
            deleted: true,
        }).eq("id", addressId);

        if (addressError) {
            messageApi.error("Error al eliminar la direccion");
            return;
        }

        messageApi.success("Direccion eliminada correctamente");

        //update the state
        fetchAddress();

    }

    return (
        <div
            className={"w-full grid 2xl:grid-cols-3 xl:grid-cols-1 gap-16"}
        >
            <Card title="Direccion"
                  bordered={false}
                  className={"min-w-[30rem] w-3/6 mx-auto"}
            >
                {contextHolder}
                <Alert
                    banner
                    type="info"
                    message={
                        <Marquee pauseOnHover gradient={false}>
                            Por lo menos una direccion de tipo &nbsp; <b> Fisica </b> &nbsp; es requerida, puede agregar
                            mas
                            direcciones despues. &nbsp;&nbsp;&nbsp;
                        </Marquee>
                    }
                    className={"mb-4"}
                />
                <Form
                    {...layout}
                    form={form}
                    name="add-citizen"
                    onFinish={handleAddAddress}
                >

                    <Form.Item name="address" label="Direccion" rules={[{
                        required: true,
                        message: 'Informacion requerida',
                    }]}>
                        <Input
                            onInput={(e) => {
                                e.currentTarget.value = e.currentTarget.value.toUpperCase();
                            }}/>
                    </Form.Item>
                    <Tooltip
                        title="Asegurese de ingresar un codigo postal valido, de lo contrario podria tener problemas al momento de generar una factura">
                        <Form.Item name="zip" label="Codigo Postal" rules={[{
                            required: true,
                            message: 'Informacion requerida',
                            pattern: new RegExp(/^\d{4,5}$/),
                        }]}>

                            <Input/>
                        </Form.Item>
                    </Tooltip>
                    <Form.Item name="reference" label="Referencias">
                        <TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name="_type_address_id" label="Tipo de direccion"
                               rules={[{
                                   required: true,
                                   message: 'Informacion requerida',
                               }]}>
                        <Select
                            placeholder="Seleccione un tipo de direccion"
                            allowClear
                            showSearch
                        >
                            {addressTypes?.map((identificationType) => (
                                <Option key={identificationType.id}
                                        value={identificationType.id}>{identificationType.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Guardar
                        </Button>
                        <Button
                            type={"link"}
                            onClick={() => navigate("/Padron")}
                        >
                            Finalizar
                        </Button>
                    </Form.Item>
                </Form>

            </Card>

            <Table className={"2xl:col-span-2 xl:col-span-1"} columns={columns} dataSource={address} key={"table"}/>
        </div>
    )
}

export default AddressForm