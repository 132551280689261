import {Link, Outlet, useNavigate} from "react-router-dom";
import {useSupabase} from "../../_supabase/SupabaseProvider";
import {
    PieChartOutlined,
} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Button, ConfigProvider, Divider, Layout, Menu, message, theme, Tooltip} from 'antd';
import {useCallback, useEffect, useState} from "react";
import {faAddressBook, faHandshake, faSun, faUser, faMoon} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons/faArrowRightFromBracket";
import {Content} from "antd/es/layout/layout";
import {useTheme} from "../../context/ThemeContext";
import {Database} from "../../_supabase/supabase_types";

const {Header, Sider} = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

const items: MenuItem[] = [
    getItem('Inicio', '/', <PieChartOutlined/>),
    getItem('Contratos', 'Agreement', <FontAwesomeIcon icon={faHandshake}/>),
    getItem('Padron', 'Padron', <FontAwesomeIcon icon={faAddressBook}/>),
];

const AdminLayout: React.FC = () => {

    const {supabase, handleLogout} = useSupabase();
    const [messageApi, contextHolder] = message.useMessage();

    const {defaultAlgorithm, darkAlgorithm} = theme;

    const navigate = useNavigate();
    const {isDarkMode, toggleDarkMode} = useTheme();

    const [collapsed, setCollapsed] = useState(false);
    const [publicServant, setPublicServant] = useState<Database["public"]["Tables"]["Public_Servants"]["Row"] | null>(null);
    const [publicServantRole, setPublicServantRole] = useState<Database["public"]["Tables"]["Role"]["Row"] | null>(null);

    const fetchUserData = useCallback(async () => {

        if (publicServant) return;

        try {
            console.log('fetchUserData');
            const {data: {session: currentSession}} = await supabase.auth.getSession();

            if (currentSession) {
                const {data: publicServantConsulted, error: publicServantErrorConsulted} = await supabase
                    .from('Public_Servants')
                    .select('*')
                    .eq('_user_id', currentSession?.user?.id)
                    .eq('active', true)
                    .eq('deleted', false)
                    .single()

                if(publicServantErrorConsulted) throw new Error('Ocurrio un error al consultar los datos de tu usuario, por favor contacta a soporte');
                if (!publicServantConsulted) throw new Error('No se encontraron datos de tu usuario, por favor contacta a soporte');
                setPublicServant(publicServantConsulted);

                if (!publicServantRole){
                    const {data: publicServantRoleConsulted, error: publicServantRoleErrorConsulted} = await supabase
                        .from('Role')
                        .select('*')
                        .eq('id', publicServantConsulted._role_id)
                        .eq('deleted', false)
                        .single()

                    if(publicServantRoleErrorConsulted) throw new Error('Ocurrio un error al consultar los datos de tu usuario, por favor contacta a soporte');
                    if (!publicServantRoleConsulted) throw new Error('No se encontraron datos de tu usuario, por favor contacta a soporte');
                    setPublicServantRole(publicServantRoleConsulted);
                }

            }
        }
        catch (error: any){
            messageApi.destroy();
            messageApi.error(error.message);
        }

    }, [publicServant, supabase])

    useEffect(() => {
        //get current session
        fetchUserData();

    }, [fetchUserData, isDarkMode])

    const onClick: MenuProps['onClick'] = (e) => {
        navigate(e.key as string);
    };

    return (
        <ConfigProvider
            theme={{algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm}}
        >
            {contextHolder}
            <Layout style={{minHeight: '100vh'}}>
                <Sider theme={"dark"} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <div style={{height: 32, margin: 16, background: 'rgba(144,56,248,0.55)'}}/>
                    <Divider className={"bg-indigo-900"} />
                    <Menu theme={"dark"}
                        onClick={onClick} defaultSelectedKeys={['1']} mode="inline" items={items}/>
                </Sider>
                <Layout>
                    <Header className="bg-transparent">
                        <div className="flex justify-end items-center h-full px-4 gap-2">
                            <div className="flex items-center">
                                <Button shape="circle" icon={!isDarkMode ? <FontAwesomeIcon icon={faMoon}/> :
                                    <FontAwesomeIcon icon={faSun}/>}
                                        onClick={toggleDarkMode}/>
                            </div>
                            <div className="flex items-center">
                                <Tooltip title={publicServantRole?.name}>
                                    <Link to={"/Perfil"}>
                                        <Button shape="round" icon={<FontAwesomeIcon icon={faUser}/>}>
                                            &nbsp; {publicServant?.name}
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className="flex items-center">
                                <Tooltip title="Salir">
                                    <Button shape="circle" icon={<FontAwesomeIcon icon={faArrowRightFromBracket}/>}
                                            onClick={handleLogout}/>
                                </Tooltip>
                            </div>
                        </div>
                    </Header>
                    <Content className={"overflow-auto py-4 px-8"}>
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}
export default AdminLayout